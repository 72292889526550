import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTerminal } from "../hooks/use-terminal"

const NotFoundPage = () => {
  const terminalRef = React.useRef<HTMLDivElement>(null)
  useTerminal(terminalRef.current, MOTD)

  return (
    <Layout>
      <Seo title="H4x0r - Hey, you're on the wrong page" />
      <div className="terminal-wrapper" ref={terminalRef}></div>
    </Layout>
  )
}

export default NotFoundPage

const MOTD = `

                 /'.    /|    .'\
           ,._   |+i\  /++\  / +|    ,,
           |*+'._/+++\/+ ++\/+++<_.-'+|
      :-.  \ ++++?++ +++++*++++++ +++ /  .-:
      |*+\_/++++ +++*++ ++++++ ++?++++\_/ +|
  ,    \*+++++ ++++ +++*+++ ++++ +++ +++++/   ,
  \'-._> +__+*++__*+++_+__*++ ++__++++__*<_.-'/
   \`>*+++|  \++/  |+*/     \`\ +|  |++/  |++++<'
_,-'+ * +*\  \/  /++|__.-.  |+ |  |+/  /+ +*+'-._
'-.*+++++++\    /+ ++++++/  / *|  |/  /+ ++++++.-'
    > *+++++\  /*++++ +/\` /\`+++|     < *++ +++< 
_,-'* +++ ++|  |++ +*/\` /\` +* +|  |\  \+ ++++++'-._
\`-._+ +*++?+|  |+++*|  '-----.+|  |+\  \+* ++ +_.-'
   _\`\++++++|__|+ *+|________|+|__|++\__|++++/\`_
  /*++_+* + +++++ ++ + ++++ +++++ ++ ++++ ++_+*+\
  '--' \`>*+++ +++++ +++++*++++  +++ ++++ ?<' '--'
       /++_++ ++ ++++++ ++?+ +++++*+++ ++++ \
       |_/ \`\++ ++ +++*++++++++++ ++++*./\`\_|
            /+*.-.*+ +_ ++*+ _+++ .-.* +\
           | /   | +/ \`\?+/\` \*+|    \ |
             '    \.'    |/    './     '

  
    Something's wrong 🐞, seems that you found the 404e22f page!

    Lets reset that clock. Navigate to: https://josemoreira.eu 

`